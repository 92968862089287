@import "../../scss/config";
.login_back_section {
  @include fluid-padding-double(
    $min_width,
    $max_width,
    $min_padding_14,
    $max_padding_14,
    $min_padding_20,
    $max_padding_20
  );
  .login_btn,
  h6 {
    margin-left: auto;
    margin-right: auto;
  }
  h6 {
    width: fit-content;
  }
  .mb_15 {
    margin-bottom: 10px;
  }
  .login_form {
    margin: 25px auto 0;

    .rdt {
      width: 100%;
      display: none;
    }
  }
  p {
    margin: 20px auto 10px;
    width: fit-content;
  }
}
