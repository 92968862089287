@import "../../scss/config";
.back_drop {
  width: 100%;
  height: 100%;
  position: fixed;
  background: $transparent-blue;
  top: 0;
  left: 0;
  z-index: 99999;
}
.modal {
  .close_button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    z-index: 99999;
    color: $primary-red;
  }
  form {
    background: $primary-white;
    padding: 25px;
    border-radius: 10px;
    z-index: 999999999;
    position: relative;
  }
}
.prompt {
  z-index: 999999;
  .prompt_content {
    position: fixed;
    z-index: 9999999;
    top: 20%;
    left: 25%;
  }
}
