@import "../../scss/config";
.checkbox_container_round {
  button {
    border: 2.2px solid $primary-blue;
    border-radius: 20px;
    width: 19px;
    height: 19px;
    background-color: $primary-white;
    transition: background-color 0.2s ease;
  }
  .checkbox_round_active {
    background-color: $primary-blue;
  }
}
.checkbox_container_square {
  button {
    border: 2.2px solid $primary-black;
    border-radius: 3.5px;
    width: 17px;
    height: 17px;
    background-color: $primary-white;
    transition: background-color 0.2s ease;
    svg {
      margin-left: -5px;
    }
  }
}
