@import "../../scss/config";
.header {
  background: $primary-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 14px 4.4% 14px 9.9%;
  height: fit-content;
  width: 100%;
  margin-bottom: 28px;
  position: fixed;
  z-index: 99999;
  top: 0;
  figure {
    margin: 0;
    img {
      width: 35px;
      height: 35px;
    }
  }
}
