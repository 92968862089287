.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  animation: all 1s ease;

  img {
    animation: fading 1s linear infinite;
    width: 80px;
    height: 80px;
  }
}

@keyframes fading {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
