@import "../../scss/config";
.image_file {
  input {
    display: none;
  }
  img {
    width: 120px;
    height: 120px;
    z-index: 999;
    border-radius: 5px;
    border: dashed 5px $transparent-blue;
  }
}
