@import "../../scss/config";

.text_card {
  background: $primary-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  height: fit-content;
  width: 100%;
  padding: 22px 24px 17px 27px;
  position: relative;
  .text_card_image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 150px;
  }
}
.image_text_card {
  h6,
  label,
  p {
   // margin-left: 150px;
  }
  .tippy_label{
    margin-left: 150px;
  }
  // .ellipses_end {
  //   padding-left: 155px;
  // }
}
.pending{
  background-color: rgb(211, 56, 56);
  width: 60px;
  color: white;
  border-radius: 3px;
  padding: 6px;
  
}
.processing{
  background-color: rgb(184, 175, 53);
  width: 90px;
  color: white;
  border-radius: 3px;
  padding: 6px;
  
}
.completed{
  background-color: rgb(46, 148, 59);
  width: 44px;
  color: white;
  border-radius: 3px;
  padding: 6px;
  
}
.red_label{
  color: rgb(161, 40, 40);
  font-size: 12px;
}