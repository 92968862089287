/** @format */

.p_10 {
	padding: 5px;
}

.text-color {
	color: #000;
}

.input-box {
	width: 60%;
	margin-bottom: 10px;
}
