@import "../../scss/config";

.post_card {
  border-radius: 9px;
  background: $primary-white;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  height: fit-content;
  position: relative;

  .post_card_image {
    width: 100%;
    height: 98px;
    border-radius: 9px 9px 0 0;
    min-height: 98px;
  }
  .card_check_box {
    border: 2.5px solid $primary-white;
    border-radius: 4px;
    background: transparent;
    position: absolute;
    width: 23px;
    height: 23px;
    top: 10px;
    left: 10px;
  }
  .card_captions {
    height: fit-content;
    background: $primary-white;
    padding: 10px 10px 17px 13px;
    border-radius: 0 0 9px 9px;
    label {
      white-space: nowrap;
    }
  }
}
