@import "../../scss/config";

.text_editor_container {
  .editorClassName {
    background: $primary-white 0% 0% no-repeat padding-box;
    border: 2px solid $light-gray;
    border-radius: 5px;
    @include fluid-type($min_width, $max_width, $min_font_19, $max_font_19);
    color: $primary-black;
    height: 200px;
    @include fluid-padding-double(
      $min_width,
      $max_width,
      $min_padding_14,
      $max_padding_14,
      $min_padding_20,
      $max_padding_20
    );
    &:hover,
    &:focus,
    &:active {
      border: 2px solid $primary-blue;
      outline: none;
    }
    .public-DraftEditorPlaceholder-root {
      color: $light-gray;
      @include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
    }
  }
}
