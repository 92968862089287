.info_important{
    background-color: rgba(247, 202, 167, 0.866);
  border-radius: 8px;
  margin-bottom: 1em;
    color:black;
    font-size: 12px;
    text-align: left;
    font-weight: regular;
    padding-top: .3em;
    padding-bottom: .3em;

}
.info_important p{
    margin:.5rem 1rem .5rem 1rem;
    text-align: left;
}