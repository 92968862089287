@import "../../scss/config";

.tabs_container {
  width: 100%;
  overflow: scroll;
  border-radius: 20px 20px 0px 0px;

  ::-webkit-scrollbar {
    width: 0px !important;
  }
  ul {
    margin: 0;
    padding: 0;
    border-radius: 20px 20px 0px 0px;
    li {
      border-right: 1px solid rgba(0, 0, 0, 0.207);
      border-top: 1px solid rgba(0, 0, 0, 0.207);
      border-bottom: 1px solid rgba(0, 0, 0, 0.207);
      list-style-type: none;

      a {
        text-decoration: none;
        div {
          white-space: nowrap;
          color: $primary-black;
        }
      }
    }
    li:first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.207);
      border-radius: 20px 0px 0px 0px;
    }
    .nav_setting {
      padding-left: 80px;
      width: 100%;
    }
    .active_nav_item {
      border: none;

      border-right: 1px solid rgba(0, 0, 0, 0.207);

      div {
        font-weight: bold;
      }
    }
  }
  .nav_item_first {
    border-left: none !important;
  }
  .nav_item_last {
    border-right: none !important;
  }
  label {
    white-space: nowrap;
  }
}
